import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import '@mantine/core/styles/global.css';
import { 
    Paper, 
    TextInput, 
    PasswordInput, 
    Button, 
    Title, 
    Container, 
    rem,
    Text
} from '@mantine/core';
import { IconLock, IconUser } from '@tabler/icons-react';
import { useAuth } from '../contexts/AuthContext';
import "./loginscreen.css";

const USERNAME = "admin";
const PASSWORD = "passnow";

const LoginScreen = () => {
  const [loadingLogin, setLoadingLogin] = useState(false);  
  const [loginError, setLoginError] = useState(null); 
  const { login } = useAuth();
  const navigate = useNavigate();

  const [loginFormData, setLoginFormData] = useState({
    username: "",
    password: "",
  });

  const handleLoginChange = useCallback((event) => {
    const { name, value } = event.currentTarget;
    setLoginFormData(prevData => {
      const newData = { ...prevData, [name]: value };
      return newData;
    });
  }, []);

  const validateLoginForm = (data) => {
    return data.username.trim() !== '' && data.password.trim() !== '';
  };

  const loginUser = (e) => {
    e.preventDefault();
   // console.log('Login attempted with:', loginFormData); // Debug log
    setLoginError(null);

    if (!validateLoginForm(loginFormData)) {
      setLoginError("Please fill in all required fields.");
      return;
    }

    setLoadingLogin(true);
    
    setTimeout(() => {
      if (loginFormData.username === USERNAME && loginFormData.password === PASSWORD) {
        login("signedin")
        navigate("/");
      } else {
        setLoginError("Invalid username or password.");
      }
      setLoadingLogin(false);
    }, 1000);
  };

  //console.log('Rendering LoginScreen, current state:', loginFormData); // Debug log

  return (   
    <div style={{ 
      backgroundColor: 'white', 
      minHeight: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Container size={420} my={40}>
        <Paper radius="md" p="xl" withBorder style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
          <Title ta="center" order={2} mb={rem(30)}>
            Login :: Standbasis School Quality Management Kit
          </Title>

          {loginError && <div className="error-message">{loginError}</div>}
          <form onSubmit={loginUser}>
            <TextInput
              label="Username"
              placeholder="Your username"
              required
              name="username"
              value={loginFormData.username}
              onChange={handleLoginChange}
              leftSection={<IconUser size={16} stroke={1.5} />}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              mt="md"
              name="password"
              value={loginFormData.password}
              onChange={handleLoginChange}
              leftSection={<IconLock size={16} stroke={1.5} />}
            />
            <Button fullWidth mt="xl" type="submit" loading={loadingLogin}>
              Sign in
            </Button>
          </form>

        </Paper>
      </Container>
    </div>
  );
};

export default LoginScreen;