export const dataschoolacademicperfdata = 
[
    {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "Aggregate scores and average them for a single figure of performance",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "Aggregate scores and average them for a single figure of performance",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "Weekly",
        "filled": false,
        "value": null,
        "option_one": "1 per week = 5",
        "option_two": "fortnightly = 3",
        "option_three": "more than a monthly = 0"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "Aggregate scores and average them for a single figure of performance",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "There should be a mid-term test for every full term ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "Aggregate scores and average them for a single figure of performance",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "There should be terminal exam for every completed term",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "Aggregate scores and average them for a single figure of performance",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic Performance Internal",
        "inquiry": "what % of students completed the term with 5 subjects at >=60% including english and maths? ",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic performance External",
        "inquiry": "Use a, b, c, baseline scores to calculate gross score and performance.  All below C do not count in Standbasis Score",
        "filled": false,
        "value": null,
        "option_one": "WAEC grading used = 5",
        "option_two": "not = 0",
        "option_three": "NULL"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic performance External",
        "inquiry": "what % of students population scored credit in 5 subjects including english and maths? ",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic performance External",
        "inquiry": "Use a, b, c, baseline scores to calculate gross score and performance.  All below C do not count in Standbasis Score",
        "filled": false,
        "value": null,
        "option_one": "WAEC grading used = 5",
        "option_two": "not = 0",
        "option_three": "NULL"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic performance External",
        "inquiry": "what % of students population scored credit in 5 subjects including english and maths? ",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic performance External",
        "inquiry": "Use a, b, c, baseline scores to calculate gross score and performance.  All below C do not count in Standbasis Score",
        "filled": false,
        "value": null,
        "option_one": "WAEC grading used = 5",
        "option_two": "not = 0",
        "option_three": "NULL"
      },
      {
        "area": "Academic Performance",
        "subarea": "Academic performance External",
        "inquiry": "what % of students population scored credit in 5 subjects including english and maths? ",
        "filled": false,
        "value": null,
        "option_one": "80% up = 5",
        "option_two": "70 - 79 = 3",
        "option_three": "50 - 59 = 1"
      }
]