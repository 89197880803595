export const dataschoolsshedata = 
[
    {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Any provision for Perimeter separation and access limiting?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some classrooms = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Any provision for Potential of dangerous intrusion?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some classrooms = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Are there Potentials of accidents and injury; are there makings for guidance for safe evacuation?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some classrooms = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Any Emergency plan and guidelines including fire, flood, storm and structure collapse; and supporting facilities including well serviced fire extinguishers ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some classrooms = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Formal arrangement for support that could be called in during safety emergency",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Is there evidence of SSHE communication, orientation and drills",
        "filled": false,
        "value": null,
        "option_one": "yes good = 5",
        "option_two": "not much = 3",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Safety",
        "inquiry": "Is there any dedicated safety staff",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there provision for potable water and is provided for the students use? If yes, is there management for safe scoop? Or a requirement for students to come to classes with their supply? ",
        "filled": false,
        "value": null,
        "option_one": "yes good = 5",
        "option_two": "none = 0"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there water supply around the school for hand-washing and sanitary use? ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there sufficient green grounds and spaces for pleasant and healthy occupation?  Numbers should be taken into consideration",
        "filled": false,
        "value": null,
        "option_one": "yes good = 5",
        "option_two": "none = 0"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there sanitary provision for urinating and defacation?  Is it maintained and serviced with water and tissue paper; and adequate for the population of the school?",
        "filled": false,
        "value": null,
        "option_one": "yes good = 5",
        "option_two": "none = 0"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is the atmosphere clean by your visual and smell assessment",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is the school free from injurious and distracting noises, both internal and external?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there Ease of reading the board or screen and moving around",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Are seats ergonomic and spaced for extended sitting in a classroom? ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is natural cross ventilation in place for free air exchange?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is the lighting situation healthy for children",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is the temperature ambience of the classroom good enough for children to spend long hours in and learning?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Do we have health emergency plan that covers child health emergencies?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there a well serviced first-aid box and a staff, either dedicated professional or delegated officer in charge",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Does the school have call-up understanding with a health service provider, public or private?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Health",
        "inquiry": "Is there evidence of health program communication in the school premises?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Environment",
        "inquiry": "Any observable pollution of the air, possibly from industrial activities in the immediate vicinity?",
        "filled": false,
        "value": null,
        "option_one": "free = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Environment",
        "inquiry": "Any observable noise pollution, possibly from commercial activities, industrial activities or traffic in the immediate vicinity?",
        "filled": false,
        "value": null,
        "option_one": "free = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Environment",
        "inquiry": "Any observable environmental pollution in the grounds of the school, possibly from commercial activities, industrial activities or natural phenomenon / incident?",
        "filled": false,
        "value": null,
        "option_one": "free = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Perimeter protection?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is there a gate at every access to the school grounds?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Strictly functionally, is the gate fit for purpose?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Do classroom and other students work space equipped for lock-down, as in protective doors?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Strictly functionally, are the doors fit for purpose?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Are the gates manned, at least during school hours?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is access into classroom and student work spaces controlled as in swipe card or locked doors when classes are in session?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is identity card display mandatory within the school?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is visit to the school grounds regulated and enforced, especially during ?school in session?? ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Are visitors clearly identified through display of identity cards during visits?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is there a student pick-up procedure and enforced?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is there in-house security staff ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Does the school have a formal security emergency plan?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is there evidence of pervasive communication of security plans and ?gates? in the school, and evidence of security drills and drills time-table? ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      },
      {
        "area": "Safety, Health, Environment, Security",
        "subarea": "Security",
        "inquiry": "Is there a functional arrangement with external support to call in if necessary? (communication numbers to the police would suffice)  ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "NULL"
      }
]