export const dataschoollearningenvironment = 
[
    {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Is there a known classroom for each class?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Can a new student or teacher find their way around the school from classroom labeling",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Count ",
        "filled": false,
        "value": null,
        "option_one": "25 down = 5",
        "option_two": "30 down = 3",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Dimensions of space available per student",
        "filled": false,
        "value": null,
        "option_one": "0.802m2 up = 5",
        "option_two": "0.62 - 0.88m2 = 3",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Safe and ergonomic?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "okay = 3",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "What is the overall feel of the seat to the pupil?",
        "filled": false,
        "value": null,
        "option_one": "comfortable = 5",
        "option_two": "okay = 3",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Adequate and good fresh air supply and circulation? (artificial alternative is ok)",
        "filled": false,
        "value": null,
        "option_one": "adequate = 5",
        "option_two": "okay = 3",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Fan or air-conditioner provided? (in the absence of good artificial ventilations) adequacy: Enough power for the need  relative to numbers and space",
        "filled": false,
        "value": null,
        "option_one": "adequate = 5",
        "option_two": "fairly = 3",
        "option_three": "not applicable = 0"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Lighting of classroom from nature? Daylight quality lighting across classroom space",
        "filled": false,
        "value": null,
        "option_one": "adequate = 5",
        "option_two": "okay = 3",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Any alternative lighting to poor or unavailable natural lighting? With brightness and color equivalent to daylight across classroom",
        "filled": false,
        "value": null,
        "option_one": "adequate = 5",
        "option_two": "fairly = 3",
        "option_three": "not applicable = 0"
      },
      {
        "area": "Learning Environment",
        "subarea": "Classroom",
        "inquiry": "Is there provision for physically disabled (as in accessing classroom on (own) wheelchair)?",
        "filled": false,
        "value": null,
        "option_one": "there is provision = 5",
        "option_two": "no provision = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Laboratory, Studio, Workshop",
        "inquiry": "If sciences are offered at completion level (SSS), is there a laboratory for each science subject offered separately?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Laboratory, Studio, Workshop",
        "inquiry": "Is there sufficient space for the largest registered class to take a practical examination?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Laboratory, Studio, Workshop",
        "inquiry": "Is there enough equipment to support the largest class in an examination? ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Laboratory, Studio, Workshop",
        "inquiry": "Is the stock of materials and reagents enough for the largest examination class?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Laboratory, Studio, Workshop",
        "inquiry": "Is there a laboratory assistant, full time, for each of the laboratories",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Laboratory, Studio, Workshop",
        "inquiry": "Have you confirmed that the laboratories are actually in use on a regular basis",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Library",
        "inquiry": "Does the school have a functional library?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Library",
        "inquiry": "Is there space enough for the largest class in the school to have a library period",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Library",
        "inquiry": "Is the library a habitable reading space",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Library",
        "inquiry": "Access the scope and quality of materials",
        "filled": false,
        "value": null,
        "option_one": "good = 5",
        "option_two": "fair = 2"
      },
      {
        "area": "Learning Environment",
        "subarea": "Library",
        "inquiry": "Is there a staff manning the library?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Library",
        "inquiry": "Is there evidence of formal and informal usage of the library in recent times?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Teaching Aids",
        "inquiry": "Is there audio visual facility in each classroom for slides and or video based instruction?  Is it in a special purpose common theater?  Or something in the line of whiteboard?",
        "filled": false,
        "value": null,
        "option_one": "all classroom = 5",
        "option_two": "some classrooms = 2",
        "option_three": "none = 0"
      },
      {
        "area": "Learning Environment",
        "subarea": "Teaching Aids",
        "inquiry": "Evidence of usage as in integration in mainstream instruction timetable?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Teaching Aids",
        "inquiry": "Is it of modern technologies (HDMI, USB, WI-fi etc)?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Teaching Aids",
        "inquiry": "For the population of the school, would the experience easily go round?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Teaching Aids",
        "inquiry": "Is the range of utilization within the mainstream training (curriculum delivery) of the school",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Outdoors",
        "inquiry": "Is there a school farm where student soil their hands in practical farming (size is not relevant)",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Outdoors",
        "inquiry": "Is there a botanical farm dedicated to agriculture and biology?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Outdoors",
        "inquiry": "Is there a geographical garden?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Outdoors",
        "inquiry": "Is there a garden (differentiate garden from farm)",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Learning Environment",
        "subarea": "Outdoors",
        "inquiry": "Is there reasonable green ground and trees?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      }
]