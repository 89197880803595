import React from "react";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import LoginScreen from "./pages/loginscreen";
import Main from "./pages/main";
import { AuthProvider, useAuth } from './contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  //console.log("Auth: " + isAuthenticated)
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
 // const location = useLocation();

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/" element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>} />          
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;