export const dataschoolacademicfirsttermdata = 
[
    {
        "placeholder": "Enrollee Count",
        "label": "Enter Enrollee Count (Number of Students only that wrote the examination)",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Enrollment Count(No. of Students x No. of Subjects)",
        "label": "Enter Enrollment Count (Volume of Students per Subjects that wrote exam)",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of A1 grades Count",
        "label": "Enter Number of A1 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of B2 grades Count",
        "label": "Enter Number of B2 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of B3 grades Count",
        "label": "Enter Number of B3 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of C4 grades Count",
        "label": "Enter Number of C4 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of C5 grades Count",
        "label": "Enter Number of C5 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of C6 grades Count",
        "label": "Enter Number of C6 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of D7 grades Count",
        "label": "Enter Number of D7 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of E8 grades Count",
        "label": "Enter Number of E8 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "Number of F9 grades Count",
        "label": "Enter Number of F9 grades",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": " Number of Absent Students",
        "label": "Enter Number of Absent Students",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "passed English only",
        "label": "Enter Number of Students that passed English (Credit Passes)",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "passed Mathematics only",
        "label": "Enter Number of Students that passed Mathematics (Credit Passes)",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
    {
        "placeholder": "passed Mathematics and English",
        "label": "Enter Number of Students that passed Mathematics and English (Credit Passes)",
        "description": "Be careful with this information, you only get one chance!!",
        "filled": false,
        "value": null,
    },
]