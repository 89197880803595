export const dataschoolinstructorresource = 
[
    {
      "area": "Instructor Resource",
      "subarea": "Instructor Resource",
      "inquiry": "What is the ratio of teacher number to student number  (tn/sn)",
      "option_one": "10% up = 5",
      "option_two": "8% up = 3",
      "option_three": "5% up = 1",
      "option_four": "6% up = 2",
      "filled": false,
      "value": null
    },
    {
      "area": "Instructor Resource",
      "subarea": "Instructor Resource",
      "inquiry": "What is the ratio of art teacher number to art student number",
      "option_one": "10% up = 5",
      "option_two": "8% up = 3",
      "option_three": "5% up = 1",
      "option_four": "6% up = 2",
      "filled": false,
      "value": null
    },
    {
      "area": "Instructor Resource",
      "subarea": "Instructor Resource",
      "inquiry": "What is the ratio of science teacher number to science student number",
      "option_one": "10% up = 5",
      "option_two": "8% up = 3",
      "option_three": "5% up = 1",
      "option_four": "6% up = 2",
      "filled": false,
      "value": null
    },
    {
      "area": "Instructor Resource",
      "subarea": "Instructor Resource",
      "inquiry": "What percentage of teachers numbers have >= 5 years? experience  as teacher",
      "option_one": "5% = 5",
      "option_two": "null = 0",
      "filled": false,
      "value": null
    },
    {
      "area": "Instructor Resource",
      "subarea": "Instructor Resource",
      "inquiry": "How may on the teaching staff are fulltime employees and how may are adjunct ",
      "option_one": "95% up = 5",
      "option_two": "80% up = 2",
      "option_three": "60% up = 1",
      "filled": false,
      "value": null
    }
  ]