export const datacoreprocesses = 
    [
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Does the school take class attendance?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Is attendance taken daily for the last one term (School days)?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "How many times is attendance taken on a school day?",
          "option_one": "per_period = 5",
          "option_two": "once = 1",
          "option_three": "twice = 3",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Is the policy roll-call time adhered to?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Is there a dedicated attendance register or app and the formality professionally observed?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Is attendance record kept?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Attendance record archiving reliable, sustainable & recoverable?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Evidence of supervision of the process?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Attendance management",
          "inquiry": "Evidence of attendance information being used to enforce policy & QA",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Does the school operate lesson note?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Was lesson note operated consistently during the last term over the last term?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "What is the lessons note cycle practiced by the school?",
          "option_one": "weekly = 5",
          "option_two": "fortnightly = 3",
          "option_three": "monthly = 1",
          "option_four": "triweekly = 2",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Access implementation as in functional, sustainable, professional? ",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Is lessons note kept for reference and possible reuse?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Evidence of supervision of lessons note operation?",
          "option_one": "yes = 5",
          "option_two": "adequate = 3",
          "option_three": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Is lesson note performance >= policy requirement?",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Are submissions and approvals within the stipulated days by school policy or tradition?  ",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "What is your assessment of the entire operation?  ",
          "option_one": "good = 5",
          "option_two": "fair = 3",
          "option_three": "not good = 0",
          "filled": false,
          "value": null
        },
        {
          "area": "Core Processes",
          "subarea": "Lesson Note Management",
          "inquiry": "Are completion (closure) within policy cycle times?  ",
          "option_one": "yes = 5",
          "option_two": "no = 0",
          "filled": false,
          "value": null
        },
        {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Is M&E  operated for pupils and instructors?",
            "option_one": "yes for students = 2",
            "option_two": "yes for teachers = 2",
            "option_three": "yes for both = 5",
            "option_four": "not really = 1",
            "option_five": "none = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Is there a consistent, predictable M&E  regime?",
            "option_one": "consistent = 5",
            "option_two": "not consistent = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Is the M&E scope comprehensive?",
            "option_one": "comprehensive = 5",
            "option_two": "not comprehensive = 2",
            "option_three": "lean = 1",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "M&E operation vulnerability and susceptibility to rigging",
            "option_one": "good = 5",
            "option_two": "fair = 3",
            "option_three": "weak = 1",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Is the M&E system transparent and predictable to students, instructors and parents?",
            "option_one": "transparent = 5",
            "option_two": "not transparent = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Are units of measurement conventional and popular standards of pass,  fail, excellent (>=50 as pass)",
            "option_one": "yes = 5",
            "option_two": "no = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Is record of M&E diligently kept, at least during the former term?",
            "option_one": "yes = 5",
            "option_two": "no = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Archives maintained in a reliable, recoverable and sustainable manner?",
            "option_one": "yes = 5",
            "option_two": "not quite = 2",
            "option_three": "no = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Evidence of supervision of M&E operation?",
            "option_one": "yes = 5",
            "option_two": "no = 0",
            "filled": false,
            "value": null
          },
          {
            "area": "Core Processes",
            "subarea": "M&E Management",
            "inquiry": "Evidence of M&E record being used to enforce policy, QA and QC",
            "option_one": "yes = 5",
            "option_two": "no = 0",
            "filled": false,
            "value": null
          }
    ];