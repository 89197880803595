export const dataschooltotalstudentdevdata = 
[
    {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "Is there a time slot allocated to ?games & sports? and a guideline that is binding for students to be involved?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "Is there a games master or trainer with some professional qualification Ideally, ",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "Is there a program for the year that is formalized?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "Is there fulltime officer in charge of sports and games, not just delegated teacher?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "Are there dedicated facilities for sports and games?  Rented facility would do.",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "some = 2",
        "option_three": "poor = 1"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many sports and games facilities relative to student population (2 soccer fields = 2 facilities for this particular purpose)",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "Are any of the facilities standard? Are they maintained?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many competitions established and practiced  internally relative to student population within the school",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many participants in internal sports and games competitions relative to school population interschool as in ?school group?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many trophies are sponsored for internal competitions relative to school population?  Trophy is not in terms of material value but symbolic value",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many local sports and games competitions participation relative to school population ",
        "filled": false,
        "value": null,
        "option_one": "all = 5",
        "option_two": "at lease one = 3",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many participants in local sports and games competitions relative to school population",
        "filled": false,
        "value": null,
        "option_one": "more than 1% = 5",
        "option_two": "up to 1% = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many trophies won in local sports and games competitions relative to school population",
        "filled": false,
        "value": null,
        "option_one": "1 per 100 = 5",
        "option_two": "less than 1 per 100 = 3",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many external sports and games competitions participation relative to school population (state level up) ",
        "filled": false,
        "value": null,
        "option_one": "all = 5",
        "option_two": "at lease one = 3",
        "option_three": "NULL"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many participants in external sports and games competitions relative to school population",
        "filled": false,
        "value": null,
        "option_one": "more than 1% = 5",
        "option_two": "up to 1% = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "How many trophies are won in external sports and games competitions relative to school population (last one year)",
        "filled": false,
        "value": null,
        "option_one": "1 per 100 = 5",
        "option_two": "less than 1 per 100 = 3"
      },
      {
        "area": "Total Student Development",
        "subarea": "Games & Sports",
        "inquiry": "What level would you rate the school sports, game activities and the trophies won?",
        "filled": false,
        "value": null,
        "option_one": "high quality = 5",
        "option_two": "moderate = 3",
        "option_three": "nothing = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "Does the school engage in internal, local and external competitions? ",
        "filled": false,
        "value": null,
        "option_one": "no = 0",
        "option_two": "yes 1 = 2",
        "option_three": "yes to all = 5"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many established competitions does the school run (formalized)",
        "filled": false,
        "value": null,
        "option_one": "2% up =5",
        "option_two": "1% up = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many students competed in them last year",
        "filled": false,
        "value": null,
        "option_one": "10% up = 5",
        "option_two": "5% up = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many, if any, trophies are dedicated for these competitions (check list of winners)",
        "filled": false,
        "value": null,
        "option_one": "3 per competition = 5",
        "option_two": "2 per competition = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many established competitions does the school participate locally (LGA; formalized)",
        "filled": false,
        "value": null,
        "option_one": "2% up =5",
        "option_two": "1% up = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many students competed in them last year",
        "filled": false,
        "value": null,
        "option_one": "10% up = 5",
        "option_two": "5% up = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many, if any, trophies did the school win in these competitions (check)",
        "filled": false,
        "value": null,
        "option_one": "3 per competition = 5",
        "option_two": "2 per competition = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many established competitions does the school participate (formalized)",
        "filled": false,
        "value": null,
        "option_one": "2% up =5",
        "option_two": "1% up = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many students competed in them last year",
        "filled": false,
        "value": null,
        "option_one": "10% up = 5",
        "option_two": "5% up = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "How many, if any, trophies were won in these competitions last year (check)",
        "filled": false,
        "value": null,
        "option_one": "3 per competition = 5",
        "option_two": "2 per competition = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Other Competitions",
        "inquiry": "Assess the quality of trophies and competitions mix and make a quality statement",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "okay = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Theater or event hall?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Relative to population, can it seat 75%",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Number of established and functional clubs",
        "filled": false,
        "value": null,
        "option_one": "more than 3 clubs = 5",
        "option_two": "up to 3 clubs = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Non-games/sports competitions promoted and active in school calendar",
        "filled": false,
        "value": null,
        "option_one": "more than 3 = 5",
        "option_two": "up to 3 = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Number of trophies for internal ",
        "filled": false,
        "value": null,
        "option_one": "2 and above = 5",
        "option_two": "up to 2 = 3",
        "option_three": "none =0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Non-games/sports activities promoted and active",
        "filled": false,
        "value": null,
        "option_one": "more than 3 = 5",
        "option_two": "up to 3 = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Number of trophies local",
        "filled": false,
        "value": null,
        "option_one": "2 and above = 5",
        "option_two": "up to 2 = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Non-games/sports activities promoted and active",
        "filled": false,
        "value": null,
        "option_one": "more than 3 = 5",
        "option_two": "up to 3 = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Number of trophies external",
        "filled": false,
        "value": null,
        "option_one": "2 and above = 5",
        "option_two": "up to 2  = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Does the school have an excursion calendar?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "What % was realized during the last academic year?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50% to 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "What % of the school population participated in one or more excursions?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50% to 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Comment on the quality of excursions taken",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "okay = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Socials",
        "inquiry": "Assessment of the caliber of trophies and numbers",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "okay = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "Any formal relations with other schools",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "Count (Sports and Games)",
        "filled": false,
        "value": null,
        "option_one": "more than 1 = 5",
        "option_two": "just 1 = 3"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "Count (Clubs)",
        "filled": false,
        "value": null,
        "option_one": "more than 1 = 5",
        "option_two": "just 1 = 3"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "Count (Socials)",
        "filled": false,
        "value": null,
        "option_one": "more than 1 = 5",
        "option_two": "just 1 = 3"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "Count (Academic)",
        "filled": false,
        "value": null,
        "option_one": "more than 1 = 5",
        "option_two": "just 1 = 3"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "Count (Community)",
        "filled": false,
        "value": null,
        "option_one": "more than 1 = 5",
        "option_two": "just 1 = 3"
      },
      {
        "area": "Total Student Development",
        "subarea": "Domain Relationship",
        "inquiry": "How far out is your domain relationship: across city, states, international",
        "filled": false,
        "value": null,
        "option_one": "local plus = 5",
        "option_two": "local = 3",
        "option_three": "none = 0"
      }
];