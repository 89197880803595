export const dataschoolsustainabilitydata = 
[
    {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "Is there a credible capacity development calendar",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How many development hours?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How many personnel are involved?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How many beneficiaries?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How many development hours?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "Budget for capacity development?",
        "filled": false,
        "value": null,
        "option_one": "20% up = 5",
        "option_two": "10 - 19% = 3",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How many calendar programs fell through in terms of total hours planned?",
        "filled": false,
        "value": null,
        "option_one": "0% or less than 30% = 5",
        "option_two": "more than 30% = 2",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How many beneficiaries were affected by the slip?",
        "filled": false,
        "value": null,
        "option_one": "0% or less than 30% = 5",
        "option_two": "more than 30% = 2",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "How much of the budget was not spent due to lack of funds?",
        "filled": false,
        "value": null,
        "option_one": "0% or less than 30% = 5",
        "option_two": "more than 30% = 2",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Teachers",
        "inquiry": "View abstracts, program reputation and providers",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "okay = 3",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "Is there a credible capacity development calendar",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How many development hours?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How many personnel are involved?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How many beneficiaries?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How many development hours?",
        "filled": false,
        "value": null,
        "option_one": "75% up = 5",
        "option_two": "50 - 74% = 3",
        "option_three": "below 30% = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "Budget for capacity development?",
        "filled": false,
        "value": null,
        "option_one": "20% up = 5",
        "option_two": "10 - 19% = 3",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How many calendar programs fell through in terms of total hours planned?",
        "filled": false,
        "value": null,
        "option_one": "0% or less than 30% = 5",
        "option_two": "more than 30% = 2",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How many beneficiaries were affected by the slip?",
        "filled": false,
        "value": null,
        "option_one": "0% or less than 30% = 5",
        "option_two": "more than 30% = 2",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "How much of the budget was not spent due to lack of funds?",
        "filled": false,
        "value": null,
        "option_one": "0% or less than 30% = 5",
        "option_two": "more than 30% = 2",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Capacity Development for Non-Teachers",
        "inquiry": "View abstracts, program reputation and providers",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "okay = 3",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Admission.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Staff Recruitment.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on General Punishment.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Rustication.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Lesson note.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Attendance.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Exam/promotion of students.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Access control.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Capacity Development.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Promotion of Teachers.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on M&E.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Policies & guidelines communication.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Organogram.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Academic year schedules.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Manuals.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Succession plan.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Student government.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on SHE policy.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Development plan.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there a policy and or guideline on Manuals and records Review, update or replacement.  ?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Policy, Guidelines & Manuals",
        "inquiry": "Is there evidence of implementation and or enforcement and or compliance?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Records Management",
        "inquiry": "Is there dedicated resource to hold school records?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Records Management",
        "inquiry": "Is the resource reasonably protected from degradation and damage?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Records Management",
        "inquiry": "Is there reasonable protection from theft, tampering and compromising?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Records Management",
        "inquiry": "Is record accessible, searchable and reusable?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Records Management",
        "inquiry": "Is there provision for disaster recovery?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Technology Adoption",
        "inquiry": "To what extent is IT adopted for teaching, lessons note, attendance, M&E and Capacity development? (not school administration)",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Technology Adoption",
        "inquiry": "To what extent is IT adopted for administration?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Technology Adoption",
        "inquiry": "What is the scope of usage of IT in the departments that they are adopted?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Technology Adoption",
        "inquiry": "What is the quality of ICT equipment, packages and implementations?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Technology Adoption",
        "inquiry": "What is the extent of integration of IT asset?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Technology Adoption",
        "inquiry": "What is the staff training program like, if any?",
        "filled": false,
        "value": null,
        "option_one": "high = 5",
        "option_two": "fair = 3",
        "option_three": "none = 0"
      },
      {
        "area": "Sustainability",
        "subarea": "Governance",
        "inquiry": "Is there a constituted board with terms of reference",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Governance",
        "inquiry": "Is the board constituted along professional lines?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Governance",
        "inquiry": "Is the board active according to its calendar?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Governance",
        "inquiry": "Is the board output effective?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      },
      {
        "area": "Sustainability",
        "subarea": "Governance",
        "inquiry": "Is there a clear provision for transition of board member tenures?",
        "filled": false,
        "value": null,
        "option_one": "yes = 5",
        "option_two": "no = 0",
        "option_three": "NULL"
      }
]